import React from 'react';
import {UsergroupAddOutlined, MonitorOutlined, FundViewOutlined} from '@ant-design/icons';

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      '/FieldOps.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#home',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Home</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        // subItem: [
        //   {
        //     name: 'sub0',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     name: 'sub1',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#mobile',
          children: [
            {
              children: (
                <span>
                  <p>Mobile</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#features',
          children: [
            {
              children: (
                <span>
                  <p>Features</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#pricing',
          children: [
            {
              children: (
                <span>
                  <p>Pricing</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 lxhu49rdaj-editor_css' },
  page: { className: 'home-page banner5-page lxhu3z0wvk-editor_css', id: "home" },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Easy Agricultural Operations</p>
            </span>
          </span>
        ),
        className: 'banner5-title lxhu5swvou6-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <span>
              <span>
              <p>For CPG Food Companies</p>
                
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
            <p>Track crop growth. Monitor your fields.&nbsp;</p>
              <p>360° view over your entire operations.</p>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '/app',
          className: 'banner5-button lxhuqnkv4ja-editor_css',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>Use App</p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
            <span>
              <span>
                <p style={{fontSize: "14px"}}>* No credit card required</p>
              </span>
            </span>
        ),
      }
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      '/FieldOpsBannerImage2_highres.png',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Painless Agri Operations</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        lg: 8,
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              //icon: <UsergroupAddOutlined className='content0-block-icon'/>,
              children:
                'images/content/relationship.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>Better Farmer Relationships</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Managing Expectations</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        lg: 8,
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              //icon: <MonitorOutlined className='content0-block-icon'/>,
              children:'images/content/monitoring.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>Monitoring Farmlands</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Being informed, alert and decisive</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        lg: 8,
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              //className: 'content0-block-icon',
              //icon: <FundViewOutlined className='content0-block-icon'/>,
              children:
                'images/content/360view.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>360° view on operations</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Always ahead of what's happening</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper', id: "mobile" },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img',lg: 12, md: 10, xs: 24 },
  img: {
    children: 'images/content/MobileHomeAndroid.png',
  },
  textWrapper: { className: 'content1-text', lg: 12, md: 14, xs: 24 },
  title: {
    className: 'content1-title lxi361dx6bt-editor_css',
    children: (
      <span>
        <span>
          <p>FieldOps™️ Mobile</p>
        </span>
      </span>
    ),
  },
  badges: {
    ios: {
      img: "images/content/AppStoreBadge.png"
    },
    android: {
      img: "images/content/PlayStoreBadge.png",
    }
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          Track your field visits. Fill out field control provisions. Track your
          progress throughout the season
        </p>
      </span>
    ),
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper', id: "features"},
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>You get what you <span className='highlighted-green'>INSPECT</span>, not what you <span className='highlighted-red'>EXPECT</span></p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>Informed Teams. Better Operations. More Yield. More RoI.</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/icons/yield.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Increased Yield</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Timely interventions make sure of a goog quality crop along
                  with hitting the yield target
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/icons/goodfarmers.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Increased 'Good Farmers'</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Everyone knowing what to do goes a long way in managing
                  expectations and looking out for relationships
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/icons/ops_productivity.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Increased Operational Productivity</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Each task and every team member is part of a season-long
                    operation. Thus, diligence and accountability ensures
                    harmony
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/icons/decreased_costs.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Decreased Costs</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  No unnecessary field visits, no extra costs. Timely
                  interventions cut unnecessary inputs too.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/icons/farmer_negligence.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Decreased Farmer Negligence</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    Track crop growth performance throughout the season to
                    monitor responsibilities
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'images/icons/ops_headache.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>Decreased Operational Headaches</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  No more untimely problems and unforeseen challenges. Just a
                  smooth and uninterrupted crop season.
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper', id: "pricing" },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children: '/FieldOpsPromoBanner.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Try <span className='highlighted-green'>150 ha</span> for <span className='highlighted-green'>1 year</span></p>
            <p>at <span className='highlighted-green'>NO COST</span>!</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Support all of your agricultural operations with satellite imagery
              based crop growth indices for an entire year.&nbsp;
              Add your teammates, share field notes and take control of your tasks.
            </p>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              {/* <p>€ 1 / ha / year</p> */}
            </span>
          </span>
        ),
        className: 'pricing0-pricing',
      },
      {
        name: 'button',
        className: 'pricing0-button-wrapper',
        children: {
          href: '/app',
          className: 'pricing0-button lxhuqnkv4ja-editor_css',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>Use App</p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'content',
        className: 'pricing0-content',
        children: (
            <span>
              <span>
                <p style={{fontSize: "14px"}}>* No credit card required</p>
              </span>
            </span>
        ),
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Services and Additional Features</p>
            </span>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          name: 'empty',
          childWrapper: {
            children: [
              { name: 'name', children: ' ' },
              { name: 'content', children: ' ' },
            ],
          },
        },
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>Basic Package</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>€ 1 / ha / year</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: (
                    <span>
                      <span>
                        <p>Select</p>
                      </span>
                    </span>
                  ),
                },
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <p>Small Coverage</p>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <p>
                      € 11 &nbsp;/ ha / year
                    </p>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  type: 'primary',
                  children: (
                    <span>
                      <p>Select</p>
                    </span>
                  ),
                },
              },
            ],
          },
        },
        {
          dataIndex: 'unlimited',
          key: 'unlimited',
          name: 'unlimited',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>Large Operation</p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <p>€ 8 / ha / year</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: (
                    <span>
                      <p>Select</p>
                    </span>
                  ),
                },
              },
            ],
          },
        },
        {
          dataIndex: 'unlimited~lxhwp5poe3j',
          key: 'unlimited~lxhwp5poe3j',
          name: 'unlimited~lxhwp5poe3j',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <p>Enterprise</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <p>Contact Us</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: (
                    <span>
                      <p>Contact Us</p>
                    </span>
                  ),
                },
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <p>Satellite Images - Sentinel 2</p>
                </span>
              ),
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content3',
              className: 'pricing2-table-content',
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content~lxhwp5por',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>Upload or Draw Fields</p>
                  </span>
                </span>
              ),
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>Unlimited</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>max. 1000 ha</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content~lxhwp5poqjk',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content~lxi14lje2gl',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi15e61gfo',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>Operations Period</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Annual Renewal</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <span>
                    <p>Annual Renewal</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Annual Renewal</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children: (
                <span>
                  <p>Contact Us</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi18w9sea6',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Track Weather</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <p>Define Seasons</p>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5po0st',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>
                      Track Crop Growth Performance
                     
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5poiup',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Field Control Provisions</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobkp',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <p>Define Growers</p>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi16nt03pj',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <p>NDVI, NDMI, NDWI, CI</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi16ob2e89',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Reports</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi16or9f8c',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Irrigation (mm / ha)</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi16p8frs',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Pest / Disease Early Warning</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~lxi1acd6m0m',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Leaf Nitrogen</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content~lxhwp5pobma',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>©2025 Agcurate, FieldOps™️. All Rights Reserved.</span>
      </span>
    ),
  },
};
